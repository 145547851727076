<template>
  <div class="relative">
    <div
      class="flex flex-col md:flex-row  justify-end  items-center  mb-4 mt-3"
    >
      <div class="text-center">
        <button
          @click="Print"
          type="button"
          class=" mb-2 bg-green-600  hover:border-darkblue border-green-600  text-white hover:bg-darkblue  md:text-xs md:mb-0 border-2    pl-3 pr-3 pt-1 pb-1 rounded-full"
        >
          Print Page
        </button>
        <button
          @click="EditQuote"
          type="button"
          class=" mb-2 ml-2 mr-2 bg-green-600 hover:border-darkblue border-green-600  text-white hover:bg-darkblue  md:text-xs md:mb-0 border-2    pl-3 pr-3 pt-1 pb-1 rounded-full"
        >
          Edit
        </button>
        <button
          @click="showDeleteModal = true"
          type="button"
          class=" md:text-xs mb-2 md:mb-0  border-2 bg-red-600 border-red-600 text-white hover:text-black hover:bg-white hover:border-white  pl-3 pr-3 pt-1 pb-1 rounded-full"
        >
          Delete
        </button>
      </div>
    </div>
    <div>
      <transition name="slide-fade">
        <NotificationModal
          v-if="showDeleteModal"
          :modalData="modalData"
          @modal-success="DeleteQuote($event)"
          @modal-close="CloseModal($event)"
        />
      </transition>
    </div>
    <div v-if="loading.quote" class="flex justify-center flex-col mb-2">
      <div>
        <p class="text-red-700 text-center md:text-xs font-bold  ">
          Loading,please wait...
        </p>
      </div>
      <div class="mt-2">
        <svg
          class="ml-auto mr-auto animate-spin"
          width="25"
          height="25"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M48 25C48 12.2975 37.7025 2 25 2"
            stroke="black"
            stroke-width="4"
          />
          <path
            d="M48 25C48 37.7025 37.7025 48 25 48"
            stroke="white"
            stroke-width="4"
          />
          <path
            d="M2 25C2 12.2975 12.2975 2 25 2"
            stroke="white"
            stroke-width="4"
          />
          <path
            d="M25 48C12.2975 48 2 37.7025 2 25"
            stroke="#C9000C"
            stroke-width="4"
          />
        </svg>
      </div>
    </div>

    <div id="fortunatePrint" class="bg-white p-3 md:p-6 mb-4 rounded-lg">
      <LetterHead :data="letter_head_data" />

      <div class="printClear">
        <div class="mt-6 overflow-x-auto">
          <t-table
            id="my-table"
            :classes="table_classes"
            :headers="tableHeader"
            :data="tableData"
          >
            <template slot="column" slot-scope="props">
              <td v-if="props.text === 'action'" :class="props.tdClass">
                <button
                  @click="DeletePayment(props)"
                  class="text-white font-bold  bg-red-600 md:pl-3 pl-2 hover:bg-black pr-2 md:pr-3 pt-2 pb-2 rounded-lg"
                >
                  <img
                    class="w-5 h-auto mr-2 hidden md:inline align-middle"
                    :src="icons.pen"
                  />
                  <span class="">Delete </span>
                </button>
              </td>
              <td v-else :class="props.tdClass">{{ props.text }}</td>
            </template>
          </t-table>
        </div>

        <div
          v-if="loading.quote"
          class="flex mt-2 justify-center flex-col mb-2"
        >
          <div>
            <p class="text-red-700 text-center md:text-xs font-bold  ">
              Loading,please wait...
            </p>
          </div>
          <div class="mt-2">
            <svg
              class="ml-auto mr-auto animate-spin"
              width="25"
              height="25"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M48 25C48 12.2975 37.7025 2 25 2"
                stroke="black"
                stroke-width="4"
              />
              <path
                d="M48 25C48 37.7025 37.7025 48 25 48"
                stroke="white"
                stroke-width="4"
              />
              <path
                d="M2 25C2 12.2975 12.2975 2 25 2"
                stroke="white"
                stroke-width="4"
              />
              <path
                d="M25 48C12.2975 48 2 37.7025 2 25"
                stroke="#C9000C"
                stroke-width="4"
              />
            </svg>
          </div>
        </div>
        <p
          v-if="quote.quoteItems.length < 1 && loading.quote != true"
          class=" mt-2 text-center md:text-sm"
        >
          No items available for this quote
        </p>
      </div>

      <p class="text-sm text-center mt-2">
        <span class="text-darkblue font-bold">
          Quote Total
          <span v-if="!!quote.vat && !!quote.vatPercentage"
            >(Incuding VAT)</span
          >
          :</span
        >
        ₦{{ !!quote.quoteTotal ? quote.quoteTotal.toLocaleString() : 0 }}
      </p>
      <div v-if="!!quote.vat && !!quote.vatPercentage" class="text-center mt-3">
        <div class="mr-4 mb-4 inline-block md:mb-0">
          <p class="text-sm mb-4 ">
            <span class="text-darkblue font-bold"> Vat:</span> ₦{{
              quote.vat.toLocaleString()
            }}
          </p>
        </div>
        <div class="inline-block ml-4">
          <p class="text-sm mb-4">
            <span class="text-darkblue font-bold"> Vat %:</span
            >{{ quote.vatPercentage }}
          </p>
        </div>
      </div>
      <div class="text-center mt-5">
        <div class="mr-4 mb-4 inline-block md:mb-0">
          <p class="text-sm mb-4 ">
            Fortunate Signature
          </p>
          <hr class="border-dashed border border-black h-0.5" />
        </div>
        <div class="inline-block ml-4">
          <p class="text-sm mb-4">
            Client's Signature
          </p>
          <hr class="border-dashed border border-black h-0.5" />
        </div>
      </div>
      <p class="mt-4 text-center text-sm">{{ todayDate }}</p>
    </div>
  </div>
</template>

<script>
  import gql from "graphql-tag";
  import { printMixin } from "@/assets/helpers/printMixin";
  import { mapMutations } from "vuex";

  import LetterHead from "../../components/LetterHead.vue";
  import NotificationModal from "../../components/NotificationModal.vue";
  export default {
    name: "SingleQuote",
    props: {},
    mixins: [printMixin],

    components: {
      LetterHead,
      NotificationModal,
    },
    apollo: {
      quote: {
        query: gql`
          query quote($id: ID!) {
            quote(id: $id) {
              serialId
              clientName
              clientPhone
              clientEmail
              clientAddress
              authorId {
                name
              }
              createdAt
              quoteTotal
              vat
              vatPercentage
              quoteItems {
                description
                material
                unit
                quantity
                unitPrice
                itemTotal
              }
            }
          }
        `,
        variables() {
          return {
            id: this.$route.params.id,
          };
        },
        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loading.quote = true;
            return;
          }

          this.loading.quote = false;
        },
      },
    },
    data() {
      return {
        quote: {
          quoteItems: [],
        },
        showDeleteModal: false,
        modalData: {
          title: "Delete Quote",
          message:
            "Are you sure you want to delete this quote? You can't get it back if you continue.",
          cancelText: "Cancel",
          successText: "Delete",
          loading: false,
        },

        loading: {
          quote: false,
          delete: false,
        },
        letter_head_data: {
          left_side: [],
          right_side: [],
          letter_head_title: "",
        },

        table_classes: {
          table:
            "min-w-full text-darkblue divide-y md:text-center divide-gray-100 shadow-sm border-transparent border",
          thead: "border-b-2 border-darkblue md:text-xs ",
          theadTr: "bg-darkblue text-xs text-white rounded-t-lg",
          theadTh:
            "px-3 py-2 font-bold md:text-center text-left bg-transparent ",
          tbody: "bg-white divide-y divide-darkblue",
          tr: "rounded-lg text-xs md:text-base",
          td: "px-3 py-2 whitespace-no-wrap md:text-xs",
          tfoot: "",
          tfootTr: "",
          tfootTd: "",
        },

        rich_select_component_classes: {
          wrapper: "relative",
          buttonWrapper: "inline-block relative w-full",
          selectButton: "w-full flex text-left justify-between items-center",
          selectButtonLabel: "block truncate",
          selectButtonPlaceholder: "block truncate",
          selectButtonIcon: "fill-current flex-shrink-0 ml-1 h-4 w-4",
          selectButtonClearButton:
            "flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6",
          selectButtonClearIcon: "fill-current h-3 w-3",
          dropdown: "absolute w-full z-10",
          dropdownFeedback: "",
          loadingMoreResults: "",
          optionsList: "overflow-auto",
          searchWrapper: "inline-block w-full",
          searchBox: "inline-block w-full",
          optgroup: "",
          option: "cursor-pointer",
          disabledOption: "opacity-50 cursor-not-allowed",
          highlightedOption: "cursor-pointer",
          selectedOption: "cursor-pointer",
          selectedHighlightedOption: "cursor-pointer",
          optionContent: "",
          optionLabel: "truncate block",
          selectedIcon: "fill-current h-4 w-4",
          enterClass: "",
          enterActiveClass: "",
          enterToClass: "",
          leaveClass: "",
          leaveActiveClass: "",
          leaveToClass: "",
        },

        tableHeader: [
          {
            id: "itemName",
            value: "description",
            text: "Description",
            className: "",
          },
          {
            value: "material",
            text: "Material",
            className: "fortunate-table",
          },

          {
            value: "unit",
            text: "Unit",
            className: "fortunate-table",
          },
          {
            value: "quantity",
            text: "Quantity",
            className: "fortunate-table",
          },
          {
            value: "unitPrice",
            text: "Rate(₦)",
            className: "fortunate-table",
          },
          {
            value: "itemTotal",
            text: "Total(₦)",
            className: "fortunate-table",
          },
        ],

        tableData: [],

        // paginated_table_data: [],
        icons: {
          pen: require("@/assets/imgs/icons/white-pen.svg"),
        },
      };
    },
    computed: {
      todayDate: function() {
        const date = new Date(Date.now());
        return ` ${date.getDate()}/${date.getMonth() +
          1}/${date.getFullYear()}`;
      },
    },
    watch: {
      quote: {
        handler: function(newValue) {
          if (!newValue) {
            this.loading.quote = false;

            return this.$emit("show-alert", {
              type: "error",
              message: "No quote found for that id",
            });
          }
          let date = new Date(Number(newValue.createdAt));

          date = `${date.getDate()}/${date.getMonth() +
            1}/${date.getFullYear()}`;

          this.letter_head_data = {
            left_side: [
              {
                label: "CLIENT NAME",
                value: newValue.clientName,
              },
              {
                label: "PHONE NO",
                value: newValue.clientPhone,
              },
            ],
            right_side: [
              {
                label: "CREATED BY",
                value: newValue.authorId.name,
              },
              {
                label: "REG. DATE",
                value: date,
              },
            ],
            letter_head_title: `QUOTE ${newValue.serialId}`,
          };
          this.tableData = newValue.quoteItems.map((quoteItem) => {
            return {
              itemName: quoteItem.itemName,
              material: quoteItem.material,
              description: quoteItem.description,
              unitPrice: quoteItem.unitPrice.toLocaleString(),
              unit: quoteItem.unit,
              quantity: quoteItem.quantity,
              itemTotal: quoteItem.itemTotal.toLocaleString(),
            };
          });
        },
        deep: true,
      },
    },
    methods: {
      ...mapMutations(["setBreadcrumbs"]),

      TableButton() {},
      async DeleteQuote() {
        this.modalData.loading = true;

        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($id: ID!) {
                deleteQuote(id: $id) {
                  _id
                  serialId
                  isDeleted
                }
              }
            `,
            // Parameters
            variables: {
              id: this.$route.params.id,
            },
          });

          if (!errors && !!data.deleteQuote._id) {
            this.$emit("show-alert", {
              type: "success",
              message: `Quote deleted successfully`,
            });

            return this.$router.push("/app/quotes");
          }
          this.modalData.loading = false;

          if (!!errors && data == null) {
            return this.$emit("show-alert", {
              type: "error",
              message: errors[0].message,
            });
          }

          this.$emit("show-alert", {
            type: "error",
            message: `Error deleting quote,check your network or contact your developer`,
          });
        } catch (error) {
          this.modalData.loading = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error deleting quote,check your network or contact your developer`,
          });
        }
      },
      CloseModal() {
        this.showDeleteModal = false;
        this.modalData.loading = false;
      },
      EditQuote() {
        this.$router.push({
          name: "manage_quotes",
          params: { mode: "edit", id: this.$route.params.id },
        });
      },

      Change(mode) {
        this.mode = mode;
      },
    },
    mounted() {
      this.setBreadcrumbs([
        { displayName: "Quotes", pathName: "quotes" },

        {
          displayName: "Single Quote",
          pathName: "single_quote",
          params: { id: this.$route.params.id },
        },
      ]);
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
